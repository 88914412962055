<template>
    <button><slot/></button>
</template>

<style scoped>
button {
    width: 100%;
    height: 40px;
    flex-shrink: 1;
    border-radius: 38px;
    border: none;
    align-self: center;
    font-weight: var(--weight-bolder);
    font-size: 16px;
    text-transform: uppercase;

    background-color: var(--color-black);
    color: var(--color-white);
    display: block;
    margin: 0 auto;
    outline: none;
}
</style>
