
import { defineComponent } from 'vue';

export default defineComponent({
    data: ()=>({
        listScroll: false,
    }),
    props: {
        ingredients: {
            type: Array as () => FBorderI.OrderPieIngredientI[],
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: 'all'
        },
    },
    mounted(){
        const list: HTMLElement = this.$refs.list as HTMLElement;
        const container: HTMLElement = this.$refs.container as HTMLElement;

        if (typeof list == 'undefined'  || typeof container == 'undefined') return;

        this.listScroll = list.clientHeight > container.clientHeight
    },
    computed: {
        ALL_INGREDIENTS: () => 'all',
        PRE_BAKE_INGREDIENTS: () => 'pre_bake',
        POST_BAKE_INGREDIENTS: () => 'post_bake',
        mainIngredients(): FBorderI.OrderPieIngredientItemI[] {
            return this.ingredients.reduce(this.getMainIngredients.bind(this), []);
        },
        preBakeIngredients(): string {
            return this.ingredients.reduce(this.getPreBakeIngredients.bind(this), []).join(', ');
        },
    },
    methods: {
        getMainIngredients(collection: FBorderI.OrderPieIngredientItemI[], ingredientGroup: FBorderI.OrderPieIngredientI): FBorderI.OrderPieIngredientItemI[] {
            const filteredIngreidents = ingredientGroup.items.filter(this.filterMainIngredients.bind(this));
            return collection.concat( filteredIngreidents );
        },
        getPreBakeIngredients(collection: string[], ingredientGroup: FBorderI.OrderPieIngredientI): string[] {
            const filteredIngreidents = ingredientGroup.items.reduce(this.reducePreBakeIngredients.bind(this), []);
            return collection.concat( filteredIngreidents );
        },
        filterMainIngredients({kds_state}: FBorderI.OrderPieIngredientItemI): boolean{
            return kds_state.indexOf(this.type) > -1 || this.type == this.ALL_INGREDIENTS;
        },
        reducePreBakeIngredients(collection: string[], ingredient: FBorderI.OrderPieIngredientItemI): string[] {
            const isPreBakeIngredient: string | undefined = ingredient.kds_state.find((state)=>state.includes(this.PRE_BAKE_INGREDIENTS));
            if (isPreBakeIngredient) {
                collection.push(ingredient.name);
            }
            return collection;
        },
    }
});
