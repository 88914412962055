import FirebaseService from './FirebaseService';
import { reactive, ref, Ref } from 'vue';
import cookieAuth from '../cookie-auth';
const fbService = new FirebaseService();

export interface FBOrderTypesI {
    preBaked: {
        queued: FBorderI.OrderI[];
        inProgress: FBorderI.PostBakeOrderPieI[];
    };
    postBaked: Array<FBorderI.PostBakeOrderPieI | FBorderI.PostBakeOrderExtraI>;
    active: FBorderI.OrderI[];
    canceled: FBorderI.OrderI[];
    completed: FBorderI.OrderI[];
}

export const orders: FBOrderTypesI = reactive({
    preBaked: { queued: [], inProgress: [] },
    postBaked: [],
    active: [],
    canceled: [],
    completed: [],
});
export const POST_BAKE = 'post_bake';
export const PRE_BAKE = 'pre_bake';
fbService.watchShop(cookieAuth.shop(), cookieAuth.line_id(), orders);
