import config, { FireBaseConfigI } from '@/services/config';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { webApi } from '@/services/api';
import { FBOrderTypesI, POST_BAKE, PRE_BAKE } from './index';
// import * as dateFns from 'date-fns';
import parseISO from 'date-fns/parseISO';
import isToday from 'date-fns/isToday';
import add from 'date-fns/add';
import format from 'date-fns/format';
import differenceInMinutes from 'date-fns/differenceInMinutes'

export default class FirebaseService {
    // TODO leave blank, fetch from api
    protected token!: string;
    protected lineID!: number;
    protected orderWarningInterval!:  number;
    protected get configs(): FireBaseConfigI {
        const { firebaseConfig } = config();
        return firebaseConfig();
    }

    protected get databasePrefix(): string {
        const { firebaseDatabasePrefix } = config();
        return firebaseDatabasePrefix();
    }

    protected get messagingDatabasePrefix(): string {
        const { firebaseMessagingDatabasePrefix } = config();
        return firebaseMessagingDatabasePrefix();
    }

    protected _firebase = firebase.initializeApp(this.configs);

    watchShop(shopID: string | undefined, lineID: number | undefined, sortedOrders: FBOrderTypesI): void {
        if (!shopID) throw new Error('FirebaseService shopID is undefined');
        if (!lineID) throw new Error('FirebaseService lineID is undefined');

        this.lineID = lineID;

        this.auth().then(
            () => this.ref(`/${shopID}/orders`).on('value', this.flattenOrders.bind(this, sortedOrders))
        );
    }

    flattenOrders( sortedOrders: FBOrderTypesI, snapShot: firebase.database.DataSnapshot ) {
        sortedOrders.preBaked = {
            queued: [],
            inProgress: [],
        };
        sortedOrders.postBaked = [];
        sortedOrders.active = [];
        sortedOrders.completed = [];
        sortedOrders.canceled = [];

        clearInterval(this.orderWarningInterval);
        snapShot.forEach(this.forEachOrders.bind(this, sortedOrders));

        sortedOrders.preBaked.queued.sort(this.sortByStartTime.bind(this));
        sortedOrders.preBaked.inProgress.sort(this.sortByBumpTime.bind(this));
        sortedOrders.postBaked.sort(this.sortByBumpTime.bind(this));

        this.orderWarningInterval = setInterval(()=>this.handOrderWarningColor(sortedOrders), 1000);
    }

    protected formatDateString(pickupTime: string) {
        return parseISO(pickupTime);
    }

    protected forEachOrders(sortedOrders: FBOrderTypesI, orderSnap: firebase.database.DataSnapshot) {
        let order: FBorderI.OrderI = this.cleanUpOrder(orderSnap.val());
        const pickupTimeDate = parseISO(order.pickupTime);
        const orderNotForToday = !isToday(pickupTimeDate);
        const fourHoursFromNow = add(new Date(), {hours: 4});
        const pickupIsForNextDay = pickupTimeDate.getTime() > fourHoursFromNow.getTime();

        if ( orderNotForToday && pickupIsForNextDay ) return;

        if (
            typeof order.orderDetails.line_number !== 'undefined'
            && order.orderDetails.line_number !== 0
            && order.orderDetails.line_number !== this.lineID
        ) {
            return;
        }

        if (['queued'].includes(order.orderDetails.status)) {
            this.pushPreBakeOrder(sortedOrders, {...order})

        }
        if (['processing'].includes(order.orderDetails.status)) {
            this.pushOrdePies(sortedOrders, 'post_bake', {...order});
            this.pushOrdePies(sortedOrders, 'pre_bake', {...order});
        }

        if (!['complete', 'picked-up', 'cancelled'].includes(order.orderDetails.status)) {
            sortedOrders.active.push(order);
        }

        if (['complete'].includes(order.orderDetails.status)) {
            sortedOrders.completed.push(order);
        }

        if (['cancelled'].includes(order.orderDetails.status)) {
            sortedOrders.canceled.push(order);
        }
    }

    protected pushPreBakeOrder({preBaked}: FBOrderTypesI, order: FBorderI.OrderI) {
        if (order.pies) {

            order.pies = order.pies.filter( ({kds_state}) => String(kds_state).includes(PRE_BAKE));
        }
        if (order.extras) {
            order.extras = order.extras.filter( ({kds_state}) => String(kds_state).includes(POST_BAKE) && order.orderDetails.status == 'queued' );
        }

        const hasItems = (order.pies && order.pies.length > 0) || (order.extras && order.extras.length > 0);

        if (hasItems && order.orderDetails.status === 'queued') {
            preBaked.queued.push(order);
        }
    }

    protected pushOrdePies(orderTypes: FBOrderTypesI, kdsState:'pre_bake'|'post_bake', order: FBorderI.OrderI) {
        let pies: FBorderI.PostBakeOrderPieI[] = [];

        if (order.pies ) {
            pies = order.pies.reduce(this.reduceItemByKdsState.bind(this, order, 'pie', kdsState), []) as FBorderI.PostBakeOrderPieI[];
        }

        if (kdsState.includes(POST_BAKE)) {
            orderTypes.postBaked = orderTypes.postBaked.concat(pies);
        }

        if (kdsState.includes(PRE_BAKE)) {
            orderTypes.preBaked.inProgress = orderTypes.preBaked.inProgress.concat(pies);
        }
        if (order.extras && kdsState.includes(POST_BAKE)) {
            this._appendExtras(orderTypes, order);
        }
    }

    protected reduceItemByKdsState(
        order: FBorderI.OrderI,
        type: 'pie'|'extra',
        kds_state: 'pre_bake'|'post_bake',
        items: Array<FBorderI.PostBakeOrderPieI | FBorderI.PostBakeOrderExtraI>,
        item: FBorderI.OrderPieI | FBorderI.OrderExtraI
    ): Array<FBorderI.PostBakeOrderPieI | FBorderI.PostBakeOrderExtraI> {
        if (String(item.kds_state).includes(kds_state) && !item.labelPrinted) {
            items.push(this.itemMeta(item, type, order));
        }
        return items;
    }

    protected itemMeta(item: any, type: 'pie' | 'extra',  order: FBorderI.OrderI,) {
        const modItem = { ...item };
        modItem._warningColor = this.updateWarningColor(order);
        modItem._pickupDisplay = order._pickupDisplay;
        modItem._startTimeDisplay = order._startTimeDisplay;
        modItem._customerName = order._customerName;
        modItem.orderNumberRaw = order.orderDetails.orderNumberRaw;
        modItem.orderID = order.orderDetails.orderID;
        modItem.totalPies = order.orderDetails.totalPies
        modItem.is_pie = type === 'pie';
        modItem.is_extra = type === 'extra';

        if (order.extras) modItem.totalPies++;
        return modItem;
    }

    protected cleanUpOrder(order: FBorderI.OrderI) {
        if (!order) return order;

        if (order?.pies) {
            order.pies = order?.pies.filter(item => Boolean(item));
        }
        if (order?.extras) {
            order.extras = order?.extras.filter(item => Boolean(item));
        }
        this.updateWarningColor(order);
        if (order.pickupTime) {
            order._pickupDisplay = format(parseISO(order.pickupTime), 'h:mm aaaa');
        }
        if (order.startTime) {
            order._startTimeDisplay = format(parseISO(order.startTime), 'h:mm aaaa');
        }

        order._customerName = this.customerName(order);

        return order;
    }
    protected ref( path = '', prefix = this.databasePrefix ): firebase.database.Reference {
        return this._firebase.database().ref(`/${prefix}${path}`);
    }
    /**
     * this method must be ran before all
     */
    protected async auth(): Promise<void> {
        // if already authenticated, skip this
        if (!this.token) {
            const res = await webApi.getFirebaseToken();
            this.token = res.data.token;
        }
        try {
            await this._firebase.auth().signInWithCustomToken(this.token);
        } catch (error) {
            console.error(error);
        }
        return;
    }

    protected handOrderWarningColor(sortedOrders: FBOrderTypesI) {
        // sortedOrders.preBaked.inProgress.forEach(this.updateWarningColor.bind(this));
        sortedOrders.preBaked.queued.forEach(this.updateWarningColor.bind(this));
        sortedOrders.active.forEach(this.updateWarningColor.bind(this));
        sortedOrders.completed.forEach(this.updateWarningColor.bind(this));
        sortedOrders.canceled.forEach(this.updateWarningColor.bind(this));
    }

    protected updateWarningColor(order: FBorderI.OrderI) {
        const pickupDate: Date = this.formatDateString(order.startTime);
        const now: Date = new Date();
        const diff = differenceInMinutes(pickupDate, now);
        let color: string = 'tile-normal';
        if (diff <= 0) {
            color =  'tile--alert';
        } else if(diff < 5) {
            color = 'tile--warning';
        }

        if (color == order._warningColor ) return color;
        order._warningColor = color;
        return color;
    }

    protected customerName(order: FBorderI.OrderI): string {
        if (order?.orderDetails?.customerLastName) {
            return `${order?.orderDetails?.customerFirstName} ${order?.orderDetails?.customerLastName[0]}.`;
        } else {
            return `${order?.orderDetails?.customerFirstName}.`;
        }
    }

    protected sortByStartTime(a: FBorderI.OrderI, b: FBorderI.OrderI){
        return parseISO(a.startTime).getTime() - parseISO(b.startTime).getTime();
    }

    protected sortByBumpTime(a: FBorderI.PostBakeOrderPieI|FBorderI.PostBakeOrderExtraI, b: FBorderI.PostBakeOrderPieI|FBorderI.PostBakeOrderExtraI){
        // equal items sort equally
        if (Number(a?.kds_ts) === Number(b?.kds_ts)) {
            return 0;
        }
        // nulls sort after anything else
        else if (!a?.kds_ts) {
            return 1;
        }
        else if (!b?.kds_ts) {
            return -1;
        }
        // lowest sorts first
        else {
            return Number(a?.kds_ts) < Number(b?.kds_ts) ? -1 : 1;
        }
    }

    protected _appendExtras(orderTypes: FBOrderTypesI, order: FBorderI.OrderI) {
        const extras: any = this.itemMeta({}, 'extra', order);
        extras.items = order.extras.filter( (extra) => extra.labelPrinted === false );

        if (extras.items.length === 0) return;

        // show extras for orders without pies
        if (typeof order.pies === 'undefined') {
            orderTypes.postBaked.push(extras);
            extras.kds_ts = extras.items[0].kds_ts;
            return;
        }

        const postBakePies = order.pies.filter(({kds_state}) => kds_state.includes(POST_BAKE))
        // use last pies bump time to keep extras attached to last bumped pie
        extras.kds_ts = postBakePies[postBakePies.length-1]?.kds_ts;

        if (order.pies.length !== postBakePies.length) return;


        orderTypes.postBaked.push(extras);
    }
}
