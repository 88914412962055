<template>
    <div class="ingredients" ref="container" v-if="mainIngredients.length > 0">
        <div ref="list">
            <ul class="ingredients__group-list">
                <li class="ingredients__item"
                    v-for="ingredient of mainIngredients"
                    :key="ingredient.name"
                    v-html="ingredient.name"
                />
            </ul>
        </div>
    </div>
    <div class="ingredients__scroll-banner" v-if="listScroll">MORE INGREDIENTS ↓</div>
    <p class="ingredients__not-included" v-if="type == POST_BAKE_INGREDIENTS" v-html="preBakeIngredients" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    data: ()=>({
        listScroll: false,
    }),
    props: {
        ingredients: {
            type: Array as () => FBorderI.OrderPieIngredientI[],
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: 'all'
        },
    },
    mounted(){
        const list: HTMLElement = this.$refs.list as HTMLElement;
        const container: HTMLElement = this.$refs.container as HTMLElement;

        if (typeof list == 'undefined'  || typeof container == 'undefined') return;

        this.listScroll = list.clientHeight > container.clientHeight
    },
    computed: {
        ALL_INGREDIENTS: () => 'all',
        PRE_BAKE_INGREDIENTS: () => 'pre_bake',
        POST_BAKE_INGREDIENTS: () => 'post_bake',
        mainIngredients(): FBorderI.OrderPieIngredientItemI[] {
            return this.ingredients.reduce(this.getMainIngredients.bind(this), []);
        },
        preBakeIngredients(): string {
            return this.ingredients.reduce(this.getPreBakeIngredients.bind(this), []).join(', ');
        },
    },
    methods: {
        getMainIngredients(collection: FBorderI.OrderPieIngredientItemI[], ingredientGroup: FBorderI.OrderPieIngredientI): FBorderI.OrderPieIngredientItemI[] {
            const filteredIngreidents = ingredientGroup.items.filter(this.filterMainIngredients.bind(this));
            return collection.concat( filteredIngreidents );
        },
        getPreBakeIngredients(collection: string[], ingredientGroup: FBorderI.OrderPieIngredientI): string[] {
            const filteredIngreidents = ingredientGroup.items.reduce(this.reducePreBakeIngredients.bind(this), []);
            return collection.concat( filteredIngreidents );
        },
        filterMainIngredients({kds_state}: FBorderI.OrderPieIngredientItemI): boolean{
            return kds_state.indexOf(this.type) > -1 || this.type == this.ALL_INGREDIENTS;
        },
        reducePreBakeIngredients(collection: string[], ingredient: FBorderI.OrderPieIngredientItemI): string[] {
            const isPreBakeIngredient: string | undefined = ingredient.kds_state.find((state)=>state.includes(this.PRE_BAKE_INGREDIENTS));
            if (isPreBakeIngredient) {
                collection.push(ingredient.name);
            }
            return collection;
        },
    }
});
</script>

<style scoped>
.ingredients {
    padding-left: 21px;
    padding-right: 15px;
    margin-top: 16px;
    margin-bottom: 12px;
    max-height: 251px;
    overflow-y: scroll;
}

.ingredients__group-list {
    padding: 0;
    margin: 0;
}

.ingredients__item {
    padding: 0;
    list-style-type: none;
    font-weight: var(--weight-bold);
    font-size: 18px;
    line-height: 170%;
}

.ingredients__scroll-banner {
    margin-top: 12px;
    margin-bottom: 44px;
    line-height: 17px;
    background-color: var(--color-green);
    border-bottom: 2px solid var(--color-buttercup);
    max-width: 142px;
    margin-left: 18px;
    font-weight: var(--weight-bold);
    font-size: 12px;
    text-align: center;
}

.ingredients__not-included {
    font-size: 14px;
    line-height: 1.17;
    opacity: 0.75;
    color: var(--color-black);
    padding: 0 18px;
    margin-top: 18px;
    margin-bottom: 24px;
}

</style>
